require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

import 'bootstrap'

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
// });

import "./stylesheets/application.scss"
